<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <expurgo-bdgd-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getExpurgosBdgd"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExpurgosService from '@/services/ExpurgosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [refreshDataMixins, tourMixins],

  components: {
    ExpurgoBdgdDataTable: () =>
      import('@/components/dados/expurgo-bdgd/ExpurgoBdgdDataTable')
  },

  data: () => ({
    items: [],
    arrTour: [
      {
        element: '#btn-novo-expurgo',
        intro: 'Clicando neste botão você poderá cadastrar novos expurgos.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#expurgo-historico-search',
        intro: 'Neste campo você poderá pesquisar por expurgos já cadastrados.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#expurgo-column-entity',
        intro:
          'Clicando sobre essa coluna é possível visualizar a lista de entidades a serem expurgadas.',
        scrollTo: 'tooltip',
        position: 'bottom'
      },
      {
        element: '#expurgo-log-criacao',
        intro:
          'Aqui você poderá visualizar informações de criação do expurgo, como data e horário de registro.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ],
    loading: false
  }),

  created() {
    this.refreshData(this.getExpurgosBdgd);
  },

  methods: {
    getExpurgosBdgd() {
      this.loading = true;

      ExpurgosService.index()
        .then((responseData) => {
          this.items = responseData;
          this.iniciarTourPagina();
        })
        .finally(() => (this.loading = false));
    },
    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let arrTourFormatted = this.items.length
        ? this.arrTour
        : this.arrTour.splice(0, 2);
      this.iniciarTour(arrTourFormatted);
    }
  }
};
</script>
